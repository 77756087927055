/*
Vizion-Al/ML-Digital Marketing Responsive HTML5 Template
Author: iqonicthemes.in
Version: 1.0
Design and Developed by: iqonicthemes.in
*/

/*================================================
[  Table of contents  ]
================================================

1  Google Font
2  Import Css
3  General
4  Text color
6  Heading Title
7  Button
8  Background  color
9  Back to Top
10 Loader
11 Background overlay color
12 Extra class

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
Google Font
-----------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

/*---------------------------------------------------------------------
Import Css
-----------------------------------------------------------------------*/
/* @import url("all.min.css"); */
@import url("owl.carousel.min.css");
/* @import url("ionicons.min.css"); */
@import url("wow.css");
@import url("magnific-popup.css");
/* @import url("flaticon.css"); */
@import url("slick.css");
@import url("timeline.css");

/*---------------------------------------------------------------------
General
-----------------------------------------------------------------------*/
*::-moz-selection { background: #f14544; color: #fff; text-shadow: none; }
::-moz-selection { background: #f14544; color: #fff; text-shadow: none; }
::selection { background: #f14544; color: #fff; text-shadow: none; }
body { font-family: 'Open Sans', sans-serif; font-weight: normal; font-style: normal; font-size: 16px; line-height: 2em; color: #868894; }
a:focus { text-decoration: none !important; }
a:focus, a:hover { color: #0077b6; text-decoration: none !important; }
a, button { outline: medium none !important; color: #00b4d8; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; }
h1, h2, h3, h4, h5, h6 { font-family: 'Josefin Sans', sans-serif; font-style: normal; font-weight: 900; color: #12203c; margin: 0px; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: inherit; }
label { font-weight: normal; }
h1 { font-size: 60px; line-height: 1.3em; }
h2 { font-size: 50px; line-height: 1.3em; }
h3 { font-size: 40px; line-height: 1.3em; }
h4 { font-size: 26px; line-height: 1.3em; }
h5 { font-size: 24px; line-height: 1.3em; }
h6 { font-size: 22px; line-height: 1.3em; }
ul { margin: 0px; padding: 0px; }
hr { margin: 0; padding: 0px; border-bottom: 1px solid #ffffff; display: inline-block; width: 100%; border-top: 0px; }

/*----------------------------------------------------------------------
 Form
-----------------------------------------------------------------------*/
input, input.form-control { border: 1px solid  #8c8a8a; color: #000; font-size: 16px; height: 55px; padding-left: 30px; margin-bottom: 35px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: #c1c2c5; }
::-moz-placeholder { /* Firefox 19+ */
color: #c1c2c5; }
:-ms-input-placeholder { /* IE 10+ */
color: #c1c2c5; }
:-moz-placeholder { /* Firefox 18- */
color: #c1c2c5; }
.form-control:focus, .form-control:hover { border-color: #7767B0; box-shadow: none; }
footer input.form-control, footer .form-control:focus{color: #ffffff;}
footer input.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: #ffffff; }
footer input.form-control::-moz-placeholder { /* Firefox 19+ */
color: #ffffff; }
footer input.form-control::-ms-input-placeholder { /* IE 10+ */
color: #ffffff; }
footer input.form-control::-moz-placeholder { /* Firefox 18- */
color: #ffffff; }

/*----------------------------------------------
Font Weight
------------------------------------------------*/
.iq-fw-1 { font-weight: 100; }
.iq-fw-2 { font-weight: 200; }
.iq-fw-3 { font-weight: 300; }
.iq-fw-4 { font-weight: 400; }
.iq-fw-5 { font-weight: 500; }
.iq-fw-6 { font-weight: 600; }
.iq-fw-7 { font-weight: 700; }
.iq-fw-8 { font-weight: 800; }
.iq-fw-9 { font-weight: 900; }

/*----------------------------------------------
Letter Spacing
------------------------------------------------*/
.iq-ls-1 { letter-spacing: 1px; }
.iq-ls-2 { letter-spacing: 2px; }
.iq-ls-3 { letter-spacing: 3px; }
.iq-ls-4 { letter-spacing: 4px; }
.iq-ls-5 { letter-spacing: 5px; }
.iq-ls-6 { letter-spacing: 6px; }
.iq-ls-7 { letter-spacing: 7px; }
.iq-ls-8 { letter-spacing: 8px; }
.iq-ls-9 { letter-spacing: 9px; }

/*---------------------------------------------------------------------
Text color
-----------------------------------------------------------------------*/
.main-color { color: #00b4d8; }
.text-white { color: #ffffff; }
.text-black { color: #333333; }
.text-gray { color: #666666; }

/*---------------------------------------------------------------------
Heading Title
-----------------------------------------------------------------------*/
.title-box { margin-bottom: 75px; text-align: center; }

/*----------------------------------------------------------------------
 Buttons
-----------------------------------------------------------------------*/
.button { color: #ffffff; cursor: pointer; padding: 15px 60px; font-weight: 600; font-size: 18px; position: relative;  border: none; z-index: 2; 
	font-family: 'Josefin Sans', sans-serif; display: inline-block; -webkit-border-radius: 90px; -moz-border-radius: 90px; border-radius: 90px;
	 background-image: linear-gradient(to right, #d65619 0%, #f16624 51%, #5d2f90 100%); background-size: 200% auto; }
.button:hover, .button:focus { color: #ffffff; background-position: right center; }
.subscribe-btn { -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; padding: 15px 25px; line-height: 25px; }
.link-button { font-size: 18px; color: #00b4d8; text-transform: capitalize; }


/*---------------------------------------------------------------------
Background  color
-----------------------------------------------------------------------*/
.white-bg { background: #ffffff; }
.main-bg { background: -moz-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); background: -webkit-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); background: -ms-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); }
.black-bg { background: #333333; }
.dark-bg { background: #222222 }

/*---------------------------------------------------------------------
Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top { z-index: 999; position: absolute; margin: 0px; color: #ffffff; bottom: 1%; right: 1%; z-index: 9; font-weight: 600; overflow: hidden; }
#back-to-top .top i { font-size: 28px; vertical-align: middle; }
#back-to-top:hover .top i { top: 0; }
#back-to-top .top:hover { color: rgba(255, 255, 255, 0.6); }

/*---------------------------------------------------------------------
Loader
-----------------------------------------------------------------------*/
#loading { width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: #ffffff; z-index: 9999; }
#loading img { width: 150px; }

/*---------------------------------------------------------------------
Background overlay color
-----------------------------------------------------------------------*/

/* Background Gradient Black */
.bg-over-black-10:before { background: rgba(195, 245, 225, 1); content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: 0; }

/*---------------------------------------------------------------------
Extra class
---------------------------------------------------------------------*/
section { background: #ffffff; padding: 100px 0; }

/*---------------------------------------------------------------------
Slick Slider
---------------------------------------------------------------------*/
.slick-slide { margin: 0px 20px; }
.slick-slide img { width: 100%; }
.slick-prev:before, .slick-next:before { color: black; }
.slick-list { padding: 0 !important; }
.slick-slide { transition: all ease-in-out .3s; opacity: .2; }
.slick-active { opacity: .5; }
.slick-current { opacity: 1; }

/*----------------
 Row with equal height columns
----------------*/
.row-eq-height { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; }

/*************************
   BG - Images
*************************/
.parallax { background-size: cover !important; -webkit-background-size: cover !important; -moz-background-size: cover !important; -ms-background-size: cover !important; position: relative; z-index: 0; background-origin: initial; background-position: center center !important; background-repeat: no-repeat; }