/*
Vizion-Al/ML-Digital Marketing Responsive HTML5 Template
Author: iqonicthemes.in
Version: 1.0
Design and Developed by: iqonicthemes.in
*/

/*================================================
[  Table of contents  ]
================================================
1  Header
2  Banner
3  Features
4  Awards
5  Blog
6  Footer
7  Team
8  Breadcrumb
9  Tab
10 Login and Register form
11 Contact page
12 Challanges
13 Partner
14 Testimonial
15 services
16 Comming Soon

/*---------------------------------------------------------------------
1 Header
-----------------------------------------------------------------------*/
header { z-index: 999; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; position: absolute; left: 0; width: 100%; padding: 15px 0; }
header .logo { width: 100px; display: block; }
header .container-fluid { padding: 0 100px; }
header .logo-stiky { width: 100px; display: none; }
header .navbar-light .navbar-nav .nav-item { margin: 0 20px; }
header .navbar-light .navbar-nav .nav-link { font-size: 14px; font-weight: 400; color: #ffffff; text-transform: uppercase; letter-spacing: 1px; padding-top: 8px; padding-bottom: 0; }
header .navbar-brand { padding-top: 0; padding-bottom: 0; }
header.menu-sticky { padding: 0; position: fixed; padding-top: 0; top: 0; left: 0; display: inline-block; width: 100%; background: #ffffff; z-index: 999; -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4); }
header.menu-sticky .button.brd:hover { color: #ffffff; }
header .navbar-light .navbar-toggler { border-color: #dddddd; border-radius: 0; background: #ffffff; }
header.menu-sticky .button.brd { padding: 10px 30px; color: #ffffff; }

header .navbar-expand-lg { padding-left: 0; }
header .navbar-light .navbar-nav .nav-item a { color: #ffffff; position: relative; padding: 0; font-size: 14px; }
header .navbar-light .navbar-nav .nav-item:hover a, header .navbar-light .navbar-nav .nav-item a.active, header .navbar-light .navbar-nav .nav-item a:focus, header .navbar-light .navbar-nav .nav-item a:focus { color: #f16624; }
header .navbar-light .navbar-nav .dropdown-menu { padding: 10px 20px; top:45px;}
header .navbar-light .navbar-nav .nav-item.dropdown a:before { content: none !important; }
header .navbar-light .navbar-nav .nav-item a:before, header .navbar-light .navbar-nav .nav-item.dropdown.active a:before { position: absolute; left: 0; right: 0; margin: 0 auto; bottom: 0; width: 0; height: 3px; background: rgb(241, 63, 67); content: ""; -webkit-transition: all 0.2s ease-out 0s; -moz-transition: all 0.2s ease-out 0s; -ms-transition: all 0.2s ease-out 0s; -o-transition: all 0.2s ease-out 0s; transition: all 0.2s ease-out 0s; }
header .navbar-light .navbar-nav .nav-item:hover a:before, header .navbar-light .navbar-nav .nav-item a.active:before, header .navbar-light .navbar-nav .nav-item a:focus:before, header .navbar-light .navbar-nav .nav-item.dropdown.active a:before { width: 25px; }
header .navbar-light .navbar-nav .active>.nav-link, header .navbar-light .navbar-nav .nav-link.active, header .navbar-light .navbar-nav .nav-link.show, header .navbar-light .navbar-nav .show>.nav-link { color: #ffffff; }
header.menu-sticky .navbar-light .navbar-nav .nav-item:hover a:before, header.menu-sticky .navbar-light .navbar-nav .nav-item a.active:before, header.menu-sticky .navbar-light .navbar-nav .nav-item a:focus:before { background: #f16624; }
header.menu-sticky .navbar-light .navbar-nav .nav-item a { color: #ffffff; }
header.menu-sticky .navbar-light .navbar-nav .nav-item:hover a, header.menu-sticky .navbar-light .navbar-nav .nav-item a.active { color: #f16624; }

header .navbar-light .navbar-nav .nav-item.dropdown .dropdown-menu a { color: #222222; }
header .navbar-light .navbar-nav .nav-item.dropdown .dropdown-menu a:hover, header .navbar-light .navbar-nav .nav-item.dropdown .dropdown-menu a.active { color: #f14544;background: none; }
header .dropdown-item.active, header .dropdown-item:active { background: inherit; }
.login { font-weight: 600; display: inline-block; letter-spacing: 1px; color: #ffffff; }
.login li a { background: transparent; color: #ffffff; padding: 10px 24px; border-radius: 6px; }
.login li a:hover { color: #ffffff; }
.login li:last-child a { background-image: linear-gradient(to right, #f06826 0%, #ee1f3b 51%, #ee1f3b 100%);
    background-size: 200% auto; color: #ffffff;color: #ffffff;
    text-transform: uppercase;
    font-size: 14px; }
.login li:last-child a:hover { background-position:right center; color: #ffffff; }
header.menu-sticky .login li a.login-btn { padding: 10px 15px;font-family: 'Josefin Sans', sans-serif; }
header.menu-sticky .login li a.login-btn:hover { color: #ffffff; }
.form-row::placeholder { color: #ffffff; opacity: 1; /* Firefox */ }
.form-row:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #ffffff; }
.form-row::-ms-input-placeholder { /* Microsoft Edge */
color: #ffffff; }
header .login li.search { position: relative; margin-right: 20px; }
header .login li.search form { display: inline-block; }
header .login li.search input[type="text"] { height: 40px; font-size: 18px; display: inline-block; font-family: "Lato"; font-weight: 100; border: none; outline: none; color: #ffffff; padding: 15px; padding-right: 60px; width: 0px; position: absolute; top: 0; right: 0; background: none; transition: width 0.4s cubic-bezier(0, 0.795, 0, 1); cursor: pointer; z-index: 3; margin: -10px 0 0 0; }
header .login li.search input[type="text"]:focus:hover { border: 1px solid #bbb; }
header .login li.search input[type="text"]:focus { width: 830px; z-index: 1; border: 1px solid #bbb; cursor: text; background: -moz-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); background: -webkit-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); background: -ms-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); }
header .login li.search #btn-search { margin: -5px 0 0 0; z-index: 2; padding: 0; height: 40px; width: 63px; display: inline-block; float: right; position: absolute; top: 0; right: 15px; z-index: 2; cursor: pointer; transition: opacity 0.4s ease; }
header .login li.search #btn-search:hover { opacity: 0.8; }
.container { max-width: 1170px; }

/*---------------------------------------------------------------------
 1 Header sticky 
 -----------------------------------------------------------------------*/
header.menu-sticky { background: #5d2f90; background: -moz-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); background: -webkit-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); background: -ms-linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); position: fixed; z-index: 99; }

header.menu-sticky .navbar-light .navbar-nav li.active .nav-link, header.menu-sticky .navbar-light .navbar-nav li .nav-link:hover { color: #f16624; }
header.menu-sticky .navbar-light .navbar-nav .nav-link, header.menu-sticky .navbar-light .navbar-nav .nav-link:focus { color: #ffffff; }
header.menu-sticky .main-header { padding: 10px 90px 10px 90px; }
header.menu-sticky .sub-header { display: none; }



/*---------------------------------------------------------------------
dropdown-item
---------------------------------------------------------------------*/
.dropdown-item.active, .dropdown-item:active { color: #46c894; background-color: transparent; }

/*---------------------------------------------------------------------
2 Banner
-----------------------------------------------------------------------*/
.banner { padding: 200px 90px 65px 90px; position: relative; overflow: hidden; }
.button-banner:hover, .button-banner:focus { background-color: #ffffff; }
.banner .banner-text { padding-top: 15%; position: relative; }
.banner:before { content: ''; position: absolute; background: url('../assets/images/bg/fshap.png'); width: 100%; height: 100%; background-repeat: no-repeat; left: 0; top: 0; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }
.banner-type img { -webkit-animation: bounce 5s infinite alternate; }
@keyframes bounce {
  from { transform: translateY(0px); }
  to { transform: translateY(-25px); }
}
@-webkit-keyframes bounce {
  from { transform: translateY(0px); }
  to { transform: translateY(-25px); }
}

/*---------------------------------------------------------------------
List Style
-----------------------------------------------------------------------*/
.list-style li { margin: 0 0 15px; padding: 0; list-style: none; font-size: 16px; }
.list-style li:last-child { margin-bottom: 0; }
.list-style li i { margin-right: 10px; font-size: 18px; }
.easy-btn { padding: 10px 70px; }

/*---------------------------------------------------------------------
3 Features 
---------------------------------------------------------------------*/
.iq-features {  background: linear-gradient(0deg, rgb(26, 6, 47) 20%, rgb(70, 29, 117) 50%) ;  position: relative; margin-top: 100px;}
.iq-features::before { height: 180px; width: 100%; position: absolute; top: -85px; left: 0; transform: skewY(-5deg); background-color: rgb(70, 29, 117) ; content: ""; }
.iq-features::after { height: 180px; width: 100%; position: absolute; bottom: -85px; left: 0; transform: skewY(-5deg); background-color: rgb(26, 6, 47); content: ""; z-index: 1; }
/*.iq-features::before { height: 180px; width: 100%; position: absolute; top: -85px; left: 0; -ms-transform: skewY(-5deg);  -webkit-transform: skewY(-5deg);  transform: skewY(-5deg); background: linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); content: ""; }
.iq-features::after { height: 180px; width: 100%; position: absolute; bottom: -85px; left: 0; -ms-transform: skewY(-5deg);  -webkit-transform: skewY(-5deg);  transform: skewY(-5deg); background: linear-gradient(0deg, rgb(33, 70, 105) 0%, rgb(9, 27, 47) 100%); content: ""; z-index: 1; } */
.feature-img { z-index: 2; position: relative; }
.feature-one { position: absolute; top: -67px; left: 50px; z-index: 1; }
.feature-two { position: absolute; bottom: -50px; right: 50px; z-index: 3; }
.feature1 { padding-bottom: 250px; }
.product-feature .feature1 { padding-bottom: 150px; }
.iq-featurebox { border: 1px solid #4e6378; padding: 30px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px;position: relative;z-index: 2; }
.features-img { position: relative; }
.features-img img {
    z-index: 2;
    position: relative;
}
.feature1 .features-img:after { z-index:1;width: 54%; height: 2px; bottom: -110px; left: 0; margin: 0 auto; right: 0; transform: rotate(90deg); content: ' '; position: absolute; background-image: linear-gradient(to right, white 50%, transparent 0%); background-size: 10px 1px; background-repeat: repeat-x; background-position: 0% bottom; animation-name: iq-work-before; animation-duration: 20s; animation-timing-function: linear; animation-iteration-count: infinite; }
.feature1 .features-img.last-line:after { content: none; }
@keyframes iq-work-before {
  0% { background-position: 0% bottom; }
  100% { background-position: 100% bottom; }
}

/*-------------------------------------------------------------------
4 Awards 
---------------------------------------------------------------------*/
.iq-award { margin-top: -50px; }
.iq-awards { background: #ffffff; padding: 45px 30px; box-shadow: 0px 12px 53.01px 3.99px rgba(34, 31, 31, 0.1); -webkit-box-shadow: 0px 12px 53.01px 3.99px rgba(34, 31, 31, 0.1); -moz-box-shadow: 0px 12px 53.01px 3.99px rgba(34, 31, 31, 0.1); box-shadow: 0px 12px 53.01px 3.99px rgba(34, 31, 31, 0.1); -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.iq-awards-info { position: relative; overflow: hidden; }
.iq-awards-info::before { background: url("../assets/images/bg/pattern1.png"); height: 90px; width: 40px; content: ""; position: absolute; left: 0; top: 0; background-size: 100% 100%; }
.iq-awards-info::after { background: url("../assets/images/bg/pattern2.png"); background-size: 100% 100%; height: 90px; width: 40px; content: ""; position: absolute; right: 0; top: 0; }

/*---------------------------------------------------------------------
5 Blog
-----------------------------------------------------------------------*/
.iq-product-blog { padding-top: 200px; }
.main-blog {margin-bottom: 30px; border: 1px dashed #d5d5d5; background: #fff; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; overflow: hidden; }
section.iq-blogs { overflow: hidden; }
.owl-carousel .main-blog {
    margin-bottom: 0;
}
.iq-blogs  .blog-img { overflow: hidden; }
.iq-blogs  .blog-img img { width: 100%; overflow: hidden; transition: transform 0.5s; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.iq-blogs .main-blog:hover .blog-img img { transform: scale(1.1); }
.main-blog:hover{border-color: #f14544;}
.main-blog .blog-detail { padding: 30px 15px 15px; }
.main-blog .blog-detail .blog-info { border-top: 1px solid #e1e1e1; padding: 20px 0 0; margin-top: 15px; display: inline-block; width: 100%; }
.main-blog .blog-detail .blog-info ul li { margin-right: 10px; }
.main-blog .blog-detail .blog-info ul li:last-child { margin-right: 0; }
.main-blog .blog-detail .blog-info ul li a i { color: #f14544; margin-right: 5px; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; }
.main-blog .blog-detail .blog-info ul li a:hover i { color: #f14544; }
.main-blog .blog-detail a { font-family: 'Nunito', sans-serif; font-weight: 600; }
.main-blog .blog-detail a:hover h6, .iq-blogs .main-blog:hover a h6 { color: #f14544; }
.main-blog .blog-detail h6 { margin-bottom: 5px; }
.main-blog  .blog-info ul li a { line-height: 50px; }
.main-blog .blog-detail .blog-info a span { color: #12203c; }
.iq-blogs .blog-one { width: 18%; position: absolute; right: 0px; bottom: 0; overflow: hidden; }
.iq-blogs .owl-carousel .owl-nav { top: auto; bottom: 0px; }
.blog-left { position: absolute; left: 0; top: 0; }
.reply-btn { color: #12203c; font-family: 'Josefin Sans', sans-serif; padding: 0px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; font-weight: 700; }
.reply-btn i { vertical-align: middle; }
.iq-blogs .blog-img .comments-box img { width: auto; }
.main-blog .blog-detail .blog-info .user-img { width: 50px; display: inline-block; }
.iq-blogs .media a h6:hover { color: #f14544 }
.blog-title { font-size: 32px; }
.iq-blogs .iq-widget-search a { position: absolute; right: 15px; color: #ffffff; cursor: pointer; width: 43px; height: 43px; line-height: 43px; background: -moz-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -webkit-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -ms-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); font-size: 18px; border-radius: 90px; top: 10px; text-align: center; }
.iq-blogs .iq-widget-search input { height: 62px; box-shadow: none;}
.iq-blogs img { -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.iq-sidebar-widget li a { color: #12203c; }
.iq-sidebar-widget ul li a span { margin-top: 4px; width: 24px; height: 24px; display: inline-block; background: -moz-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -webkit-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -ms-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); line-height: 24px; text-align: center; border-radius: 90px; color: #ffffff; float: right; font-weight: normal; }
.blog-title-img { border: 2px dashed #d5d5d5; padding: 40px 20px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.blog-title-img img { width: 120px; height: 120px; }
.iq-mt-80 { margin-top: 80px; }
.left-side-blog { padding-right: 20px; }
.right-side-blog { padding-left: 20px; }
.main-blog .blog-detail .blog-info .user-img { width: 50px; height: 50px; }
.left-side-blog .media img, .right-side-blog .media img { width: 80px; -webkit-border-radius: 90px; -moz-border-radius: 90px; border-radius: 90px; }
.blog-finding ul li a i { color: #f14544; margin-right: 5px; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; }
.blog-finding ul li a { color: #868894; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.blog-finding ul li a:hover i { color: #f14544; }
.iq-blockquote { background: url("../assets/images/bg/04.png") no-repeat; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px;background-size: 100% 100%; }
.iq-blockquote h5 { padding: 40px 50px 40px  100px; position: relative; z-index: 1; }
a.month-detail { color: #6b6e75; font-weight: 700; }
a.month-detail:hover{color:#f14544;}
.blog-finding ul { border-top: 1px solid #e4e4e4; padding-top: 15px; margin-top: 15px; display: inline-block; width: 100%; }
.comments-box { padding: 30px; border: 1px solid #e4e4e4; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.comments-box img { width: 100px; }
.iq-tags li { padding-left: 2px; display: inline-block; padding-right: 2px; margin: 0 0 26px; }
.iq-tags li a { color: #868894; padding: 8px 15px; font-weight: 600; border: 1px solid #e4e4e4; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; }
.iq-tags li a:hover { border-color:#f14544;

   background-image: -moz-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%);
   background-image: -webkit-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); 
   background-image: -ms-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%);
    color: #ffffff; }
.iq-sidebar-widget li a:hover{color: #f14544; }

/*---------------------------------------------------------------------
6 Footer
---------------------------------------------------------------------*/
footer h5, footer h4 { font-weight: 500; }
footer.iq-footer.fshap { position: relative; background: url("../assets/images/fshap.png"); padding-top: 400px; padding-bottom: 50px; background-size: 100% 100%; }
footer .footer-one { position: absolute; top: 250px; left: -50px; transform: rotate(-40deg); opacity: 0.5; }
footer.iq-footer.fshap::after { position: absolute; background: url("../assets/images/bg/03.png") no-repeat; content: ""; right: 0; bottom: 0; width: 678px; height: 684px; z-index: 1; }
footer ul li { list-style: none; }
footer .footer-top { z-index: 2; position: relative; }
footer .iq-contact li i, footer .iq-contact li p { display: inline-block; }
footer .iq-contact li i { margin-right: 5px; color: #ffffff; }
footer .footer-logo img { height: 40px; }
footer .social-icone ul li a { color: #ffffff; }
footer .social-icone ul li { padding-right: 20px; }
footer .row.justify-content-between a:hover, footer .row.justify-content-between a:focus { color: rgba(0, 0, 0, .5); }
footer  li a { color: #ffffff; }
footer  li a:hover, footer  li a:focus { color: #f14544; }
footer .social-icone ul li:nth-last-child(1) { padding-right: 0; }
.iq-font-18 { font-size: 18px; }
footer .form-inline .form-control { background: none; width: 425px; margin-right: 28px; margin-bottom: 0; }
.footer-subscribe { padding-bottom: 30px; }
footer ul.info-share li{width: auto;}
ul.info-share { margin-top: 30px; }
.info-share  li { display: inline-block; margin-right: 15px; }
.info-share  li a i { font-size: 30px; color: #ffffff; }
.info-share  li a:hover i { color: #f14544; }
.logo p { padding-right: 75px; }

/*---------------------------------------------------------------------
7 Team
-----------------------------------------------------------------------*/
.iq-team:hover .iq-team-info { bottom: 0; }
.iq-team { position: relative; overflow: hidden; }
.iq-team .iq-team-info { padding: 20px; display: inline-block; width: 100%; }
.iq-team .iq-team-info span { font-family: 'Josefin Sans', sans-serif; font-weight: 600; }
.iq-team .share { background: none; position: absolute; right: -65px; top: 30%; width: 50px; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; }
.iq-team:hover .share { right: 20px; }
.iq-team .share ul li { margin: 5px 0; text-align: center; }
.iq-team .share ul li a { color: #ffffff; width: 30px; height: 30px; line-height: 30px; background: linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -webkit-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -ms-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); color: #ffffff; border-radius: 90px; text-align: center; display: inline-block; }
.iq-team .share ul li a:hover { color: #ffffff; }
.iq-team .iq-team-img { position: relative; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; overflow: hidden; }
.iq-team .iq-team-img img { width: 100%; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; transition: 1s ease; overflow: hidden; }
.iq-team:hover .iq-team-img img { -webkit-transform: scale(1.1); -ms-transform: scale(1.1); transform: scale(1.1); transition: 1s ease; }
.iq-team .iq-team-img::before { background: rgba(0, 0, 0, 0.5); position: absolute; height: 100%; width: 100%; left: 0; top: 0; content: ""; z-index: 1; bottom: 0; right: 0; opacity: 0; -webkit-transition: all 0.4s ease-in-out 0s; -moz-transition: all 0.4s ease-in-out 0s; transition: all 0.4s ease-in-out 0s; }
.iq-team:hover .iq-team-img::before { opacity: 1; }
.iq-team .share { z-index: 2; }
.iq-team .share ul { list-style: none; position: relative; padding: 5px 0 0 0; background: #ffffff; }
.iq-team .share ul::before { width: 0; height: 0; border-left: 25px solid transparent; border-right: 25px solid transparent; border-top: 25px solid #ffffff; position: absolute; bottom: -25px; content: ""; }

/*---------------------------------------------------------------------
8 Breadcrumb 
---------------------------------------------------------------------*/
.iq-breadcrumb { padding: 250px 0px 0px; background: url("../assets/images/bg/breadcrumb.png") no-repeat 0 0; background-size: 100% 100%; }
.iq-breadcrumb .container-fluid { padding: 0 90px; }
.iq-breadcrumb nav { display: inline-block; }
.breadcrumb-image { margin-top: -150px }
.breadcrumb-item a{color: #ffffff;}
.breadcrumb-item.active { background: linear-gradient(to right, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; }
.breadcrumb-item.active::before, .breadcrumb-item+.breadcrumb-item::before { color: #ffffff; -webkit-text-fill-color: #ffffff; }
.iq-breadcrumb h2 { font-weight: 600; }
.breadcrumb { background: #112942; padding: 10px 30px; font-family: 'Josefin Sans', sans-serif; margin-bottom: 0; }
.iq-breadcrumb.text-left .breadcrumb li { display: inline-block; }
.iq-breadcrumb.text-left .breadcrumb li a i { font-size: 18px; margin-right: 6px; }
.iq-breadcrumb.text-left .breadcrumb li, .iq-breadcrumb.text-left .breadcrumb li a:hover { color: #ffffff; }
.iq-breadcrumb.text-left .breadcrumb li a, .iq-breadcrumb.text-left .breadcrumb-item+.breadcrumb-item::before { color: #464855; margin-bottom: 0; }
.iq-breadcrumb .breadcrumb li a:hover{ color: #f14544; }
/*---------------------------------------------------------------------
9 Tab
---------------------------------------------------------------------*/
.iq-tab .nav-item { width: 100%; }
.iq-tab .nav-item a { background: #ffffff; color: #12203c; font-size: 20px; font-family: 'Josefin Sans', sans-serif; font-weight: 700; padding: 15px 10px; margin-bottom: 8px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; position: relative; border: inherit; }
.iq-tab .nav-pills .nav-link { border: none; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; margin-right: 2px; }
.iq-tab .nav-pills .nav-link.active, .iq-tab .nav-pills .show>.nav-link, .iq-tab .nav-pills .nav-link:hover { background: rgba(241, 102, 36, 1); background: -moz-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -webkit-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -ms-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); color: #ffffff; }
.iq-tab.horizontal .nav-item { width: 16.66%; text-align: center; margin-right: 15px;    display: inline-block; }
.iq-tab.horizontal .nav-item a { padding: 8px 10px; margin-bottom: 0; }
.iq-tab .nav-pills{    display: block;
    text-align: center;
    margin: 0 auto;}
/*---------------------------------------------------------------------
10 login and register form
-----------------------------------------------------------------------*/
.iq-login-regi { padding: 180px 0 80px 0; overflow: hidden; }
.iq-login-regi .iq-login { padding: 30px 30px; background-position: left center; background-attachment: fixed; background-repeat: repeat; border: 2px solid #5d2f90; border-radius: 10px; }
.iq-login-regi h5 { font-weight: 600; }
.iq-login-regi h5 i { color: #f14544; }
.iq-login-regi .iq-login span { font-size: 40px; }
.iq-login-regi .iq-login label { color: #222222; font-weight: 600; }
.iq-login-regi .iq-login form .form-control { border-radius: 10px; height: 52px; border: 2px solid #dedede; padding: 20px 20px; }
.iq-login-regi .iq-login form .form-control:focus { border-color: inherit; outline: 0; box-shadow: inherit; }
.iq-login-regi .iq-login form .button { margin:0 0 30px 0; width: 100%; }
.iq-login-regi .iq-login .link { color: #222222; font-weight: 600; }
.iq-login-regi .iq-media li { display: inline-block; list-style: none; padding: 0; margin: 0 18px 8px 0; }
.iq-login-regi .iq-media li a { display: block; width: 40px; height: 40px; line-height: 40px; font-size: 16px; text-align: center; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%; color: #ffffff; }
.iq-login-regi .iq-media li a.fb { background: #3d5f9a; }
.iq-login-regi .iq-media li a:hover.fb { background: #547abb; }
.iq-login-regi .iq-media li a.tw { background: #44c0f3; }
.iq-login-regi .iq-media li a:hover.tw { background: #0faef0; }
.iq-login-regi .iq-media li a.gplus { background: #dd4b39; }
.iq-login-regi .iq-media li a:hover.gplus { background: #bd3221; }
.iq-login-regi .iq-media li a.lkd { background: #0077B5; }
.iq-login-regi .iq-media li a:hover.lkd { background: #008fda; }
.iq-login-regi form .form-group { margin-bottom: 25px; }
.iq-login-regi .link a:hover { text-decoration: underline !important; }
.error img { margin-top: 80px; }
.iq-login-regi .iq-login form .email-bg { background-color: #ffff; border: 2px solid #dedede; border-radius: 5px; }
.iq-login-regi .iq-login form .email-bg:focus { border: 2px solid #f14544; color: #222 }

/*---------------------------------------------------------------------
11 contact page
-----------------------------------------------------------------------*/
.iq-contactbox .contact-box { background: #ffffff; }
.iq-contactbox .contact-box input { outline: none !important; width: 100%; height: 52px; border: 2px solid #ced4da; border-radius: 5px; padding: 5px 20px; }
.iq-contactbox .contact-box input:hover, .iq-contactbox .contact-box input:focus, .iq-contactbox .contact-box textarea:focus, .iq-contactbox .contact-box textarea:hover { border: 2px solid #f14544; }
.iq-contactbox .no-spec { padding: 0; }
.iq-contactbox iframe { height: 330px; }
.iq-contactbox .contact-box textarea { border-radius: 10px; outline: none !important; width: 100%; border: 2px solid #ced4da; padding: 5px 20px; margin-top: 10px; }
.iq-contactbox .contact-box .button { margin-top: 20px; }
.contact-footer { padding: 60px 0 30px; }
.iq-contact { position: relative; margin: 20px 0; }
.iq-contact li { list-style: none; padding: 0; margin: 0px 5px; font-size: 14px; margin-bottom: 10px; float: left;width: 100%;}
.iq-contact h5.small-title { margin-bottom: 10px; }
.iq-contact p { display: table; margin-bottom: 0px; padding-left: 5px; }
.iq-contact i { float: left; color: #12203c; display: table-cell; width: 30px; line-height: 35px; font-size: 18px; }
.iq-contactbox form { border: none; padding: 30px 0; }
.iq-contactbox h3 { font-size: 36px; }
.iq-contactbox p { font-weight: 600; }
.iq-contact-infobox { background: url("../assets/images/bg/05.png"); margin-bottom: 50px; }
.iq-contact-infobox .info-share li a i { color: #12203c; }
.iq-contact-infobox .info-share li a:hover i { color: #f14544; }
.iq-contact-infobox .contact-bg { border-radius: 5px; background: #ffffff; padding: 40px 40px; }

/*---------------------------------------------------------------------
12 Challanges
---------------------------------------------------------------------*/
.iq-challange-box { padding: 75px 30px 30px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; position: relative; border: 2px dashed #d5d5d5; border-bottom: none; background: #ffffff; }
.iq-challange-box::before { border-top: 2px dashed #d5d5d5; position: absolute; bottom: -24px; left: 0; transform: rotate(15deg); left: 0; content: ""; width: 50%; }
.iq-challange-box::after { border-top: 2px dashed #d5d5d5; position: absolute; right: 0; bottom: -24px; content: ""; width: 50%; transform: rotate(-15deg); }
.iq-challange-box:hover, .iq-challange-box:hover::before, .iq-challange-box:hover::after { border-color: #0077b6; }
.iq-challange-img { position: relative; }
.iq-challange-img::before { height: 140px; width: 140px; position: absolute; left: 0; right: 0; margin: 0 auto; text-align: center; background: #f2f2f2; -webkit-border-radius: 90px; -moz-border-radius: 90px; border-radius: 90px; content: ""; top: 0; }
.info-box { position: relative; z-index: 2; }
.iq-challange-img img { z-index: 1; position: relative; -webkit-animation: bounce 5s infinite alternate; }

/*---------------------------------------------------------------------
13 Partner
---------------------------------------------------------------------*/
.iq-partner { padding-top: 250px; }
.iq-patner-item.circle { position: relative; width: 233px; height: 233px; border-radius: 50%;text-align: center;margin:0 auto; }
.iq-patner-item.circle.effect6 .img { opacity: 1; padding: 100px 0; width: 233px; margin: 0 auto; height: 233px; text-align: center; -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); -webkit-transition: all 0.35s ease-in-out; -moz-transition: all 0.35s ease-in-out; transition: all 0.35s ease-in-out; }
.iq-patner-item.circle.effect6.colored .info { background: #1a4a72; }
.iq-patner-item.circle.effect6 .info { opacity: 0; -webkit-transition: all 0.35s ease-in-out; -moz-transition: all 0.35s ease-in-out; transition: all 0.35s ease-in-out; position: absolute; left: 0; top: 0; padding: 90px 50px; text-align: center; }
.iq-patner-item.circle.effect6 .info::before { background: url("../assets/images/partner/bg.png"); opacity: 0.2; filter: alpha(opacity=20); /* For IE8 and earlier */ height: 100%; width: 100%; content: ""; position: absolute; width: 100%; height: 100%; z-index: -1; left: 0; top: 0; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%; }
.iq-patner-item.circle.effect6 .info a { color: #12203c; font-weight: 600; font-family: 'Josefin Sans', sans-serif; font-size: 18px; }
.iq-patner-item.circle.effect6.scale_up .info { -webkit-transform: scale(0.5); -moz-transform: scale(0.5); -ms-transform: scale(0.5); -o-transform: scale(0.5); transform: scale(0.5); }
.iq-patner-item.circle.effect6.scale_up:hover .img { opacity: 0; -webkit-transform: scale(1.5); -moz-transform: scale(1.5); -ms-transform: scale(1.5); -o-transform: scale(1.5); transform: scale(1.5); }
.iq-patner-item.circle.effect6.scale_up:hover .info { opacity: 1; -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); }

/*---------------------------------------------------------------------
14 Testimonial
---------------------------------------------------------------------*/
.big-text { color: #5d2f90; font-size: 60px; }
.author { text-align: center; margin: 0 auto; }
.author-img { display: inline-block; }
.author-info { display: inline-block; text-align: left; margin-left: 15px; }
.testimonial { border: 2px dashed #d5d5d5; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; padding: 50px 15px 0px; position: relative; margin-bottom: 70px; border-bottom: none; }
.testimonial::before { border-top: 2px dashed #d5d5d5; position: absolute; bottom: -30px; left: 0; transform: rotate(15deg); left: 0; content: ""; width: 50%; }
.testimonial::after { border-top: 2px dashed #d5d5d5; position: absolute; right: 0; bottom: -30px; content: ""; width: 51%; transform: rotate(-15deg); }
.author-img img { width: 50px; -webkit-border-radius: 90px; -moz-border-radius: 90px; border-radius: 90px; }
.testimonial:hover,.testimonial:hover::before,.testimonial:hover::after{border-color: #f16624;}

/*---------------------------------------------------------------------
15 services
---------------------------------------------------------------------*/
.iq-services { padding: 45px 30px; margin-bottom: 30px; }
.iq-services:hover { color: #fff; background: linear-gradient(17deg, rgb(254, 223, 108) 0%, #f16624 0%, #e78151 100%); background: -webkit-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); background: -ms-linear-gradient(17deg, rgb(254, 223, 108) 0%, rgb(240, 104, 38) 0%, rgb(238, 31, 59) 100%); -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.flaticon { color: #0077b6; }
.flaticon::before { margin: 0; font-size: 75px; line-height: 75px; }
.iq-services:hover h5, .iq-services:hover .flaticon { color: #ffffff; }

/*---------------------------------------------------------------------
16 Coming Soon 
---------------------------------------------------------------------*/
.coming-height { min-height: 800px; vertical-align: middle; }
.iq-coming .big-text { font-size: 80px; text-transform: uppercase; color: #ffffff; font-weight: 900; line-height: 100px; font-family: 'Josefin Sans', sans-serif; }
.iq-coming img { width: 140px; }
.iq-coming form .form-control { background: rgba(255, 255, 255, 0.5); border: none; }
.iq-coming form .form-control { margin-bottom:0;background:  #ffffff;  border: none; height: 45px; padding: 0 15px 0 15px; outline: none !important; box-shadow: inherit; width: 280px; border-radius: 90px 0 0 90px; }
.iq-coming form::-webkit-input-placeholder {    color: #ffffff; }
.iq-coming form:-ms-input-placeholder {    color: #ffffff; }
.iq-coming form::-moz-placeholder {     color: #ffffff; opacity: 1; }
.iq-coming form:-moz-placeholder {      color: #ffffff; opacity: 1; }
.iq-coming form .button { box-shadow: inherit; border: none; padding: 0 15px; border-radius: 0 90px 90px 0; height: 45px; cursor: pointer;  }
.iq-coming .countdown-timer { -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; margin-bottom: 20px; max-width: 300px; margin: 50px auto; border-bottom-left-radius: 15px; }
.iq-coming .countdown-timer h5 { font-size: 14px; letter-spacing: 0.5px; text-align: center; padding-top: 10px; text-shadow: none; }
.iq-coming .countdown-timer .timer { padding: 10px; text-align: center; padding-top: 15px; }
.iq-coming .countdown-timer .timer .timer-wrapper { display: inline-block; width: 200px; height: 50px; }
.iq-coming .countdown-timer .timer .timer-wrapper .time { font-size: 80px; font-weight: bold; color: #333333; margin: 0 50px; float: left; }
.iq-coming .countdown-timer .timer .timer-wrapper .text { font-size: 20px; }
.iq-coming #countdown { list-style: none; margin: 20px 0 30px 0; padding: 0; text-align: center; display: inline-block; }
.iq-coming #countdown li { display: inline-block; margin: 0 20px; }
.iq-coming #countdown li span { font-size: 50px; font-weight: 800; line-height: 80px; color: #ffffff; position: relative; }
.iq-coming #countdown li.seperator { font-size: 50px; line-height: 40px; vertical-align: top; margin: 16px 0px; color: #ffffff; }
.iq-coming #countdown li h6 { color: #ffffff; font-size: 20px; padding-right: 30px; }
.iq-coming .particles-bg { position: relative; overflow: hidden; }
.iq-coming .particles-bg #canvas { position: absolute; display: block; color: rgba(255, 255, 255.0.9); }
.iq-coming #countdown li p { font-size: 16px; font-weight: 600; line-height: 24px; color: #ffffff; }
.iq-coming #countdown li span:after { position: absolute; right: -30px; top: 0; content: ":"; font-size: 50px; color: #ffffff; line-height: 50px; }
.iq-coming #countdown li:last-child  span:after { content: none; }


/*---------------------------------------------------------------------
Register Page
---------------------------------------------------------------------*/
.iq-registertbox .register-boxes input:hover, .iq-registertbox .register-boxes  input:focus, .iq-registertbox .register-boxes  textarea:focus, .iq-registertbox .register-boxes  textarea:hover { border: 2px solid #46c894; }

/*---------------------------------------------------------------------
Login Page
---------------------------------------------------------------------*/
.iq-loginbox .login-boxes input:hover, .iq-loginbox .login-boxes input:focus, .iq-loginbox .login-boxes textarea:focus, .iq-loginbox .login-boxes textarea:hover { border: 2px solid #46c894; }

/*---------------------------------------------------------------------
Error 404

---------------------------------------------------------------------*/

/*---------------------------------------------------------------------
cookie
-----------------------------------------------------------------------*/
#cookie_div { background: #222222; color: #ffffff; position: fixed; bottom: 0; left: 25%; width: 50%; padding: 8px 20px; display: none; z-index: 999; -webkit-box-shadow: -1px -5px 21px 1px rgba(0, 0, 0, 0.12); -moz-box-shadow: -1px -5px 21px 1px rgba(0, 0, 0, 0.12); box-shadow: -1px -5px 21px 1px rgba(0, 0, 0, 0.12); border-radius: 10px; }
#cookie_div .button { float: right; padding: 5px 20px; font-size: 12px; margin-top: 3px; line-height: 16px; font-weight: 800; border-radius: 10px; letter-spacing: 1px; }
